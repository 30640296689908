import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Nav from "../components/Nav"

export const query = graphql`query JobsPageQuery {
  teamCollage: file(relativePath: {eq: "rentware-team-collage-2-years.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  jobCard1: file(relativePath: {eq: "job-card-image-back-end.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  jobCard2: file(relativePath: {eq: "job-card-image-full-stack.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  teamMeeting: file(relativePath: {eq: "rentware-team-meeting.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  karolAndMilan: file(relativePath: {eq: "karol-and-milan-at-work.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  teamEventTennis: file(relativePath: {eq: "rentware-team-event-tennis.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  office: file(relativePath: {eq: "rentware-office-adlershof.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  logoAws: file(relativePath: {eq: "logo-aws.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoGolang: file(relativePath: {eq: "logo-golang.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoMysql: file(relativePath: {eq: "logo-mysql.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoPostgresql: file(relativePath: {eq: "logo-postgresql.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoReact: file(relativePath: {eq: "logo-react.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoRedux: file(relativePath: {eq: "logo-redux.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
  logoStencil: file(relativePath: {eq: "logo-stencil.png"}) {
    childImageSharp {
      gatsbyImageData(width: 110, layout: CONSTRAINED)
    }
  }
}
`

const JobsPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Jobs at Rentware",
          metaDescription: "It looks like you got to the jobs section",
        }}
      />
      <Nav
      // telNumber={content.navLandingPage.navTelefonNumber}
      // telCta={content.navLandingPage.navTelefonCta}
      // telNumberText={content.navLandingPage.navTelefonNumberText}
      // contactCtaMobile={content.navLandingPage.navContactCtaMobile}
      // contactCtaDesktop={content.navLandingPage.navContactCtaDesktop}
      />
      <main>
        <div className="hero__wrapper hero__wrapper--content-left">
          <div className={"hero mt-8 md:mt-0 mx-auto max-w-screen-3xl"}>
            <div className={"hero__text p-4 md:p-10 lg:p-16 xl:p-20 2xl:pl-48 md:absolute md:z-10 md:bg-secondary-bg md:w-3/5 xl:w-2/3"}>
              <h1>Building the future of rental.</h1>
              <p>With your help we will democratize the world of rental worldwide by giving owners great tools and consumers the best possible experience to enjoy their bookings.</p>
              <p className="md:mb-0">At the same time we want to make sure to have a positive impact on our environment and all people, to make our world a better place alltogether.</p>
            </div>
            <div className="w-full max-w-screen-md ml-auto hero__image">
              <GatsbyImage
                image={data.teamCollage.childImageSharp.gatsbyImageData}
                className="min-h-56"
                alt="TODO" />
            </div>
          </div>
        </div>

        <div className="max-w-screen-xl mx-auto features">
          <div className="features__item features__item--overlapp-image xl:px-0">
            <div className="px-4 py-8 features__text rw-text-block">
              <div className="max-w-screen-sm ">
                <h2 className="h1">Let’s go and invent the world of tomorrow, working side by side!</h2>
                <p>We started our journey in 2017 and launched our MVP in 2018. Until now we ‘ve pushed over 1000 updates and keep improving our quality as well as performance every day. We are pashioned about technical innovations and at the same time use well known software paradigms.</p>
                <a href="mailto:team@rentware.com" className="text-xl no-underline btn btn--primary">Interested? Just e-mail us.</a>
              </div>
            </div>
            <div className="w-full max-w-lg px-4 mx-auto lg:mr-auto features__image xl:px-0 lg:-ml-8">
              <div className="overflow-hidden shadow-lg rounded-2xl">
                <div className="relative">
                  <GatsbyImage
                    image={data.jobCard1.childImageSharp.gatsbyImageData}
                    alt='collage of the rentware team casual' />
                  <h3 className="absolute bottom-0 left-0 px-5 py-2 text-2xl font-bold text-white bg-primary-font/80 w-52">Back-end</h3>
                </div>
                <div className="relative p-8 pt-20 text-lg">
                  <h4 className="absolute top-0 left-0 px-5 py-2 font-bold text-white bg-primary-font/80 w-52">Software engineer</h4>
                  <p className="mb-2">We are looking for a back-end engineer, preferable with pre-existing knowledge in Golang and SQL, as well as experience with AWS.</p>
                  <p>Sounds interesting? <a className="underline" href="mailto:team@rentware.com">Great, just reach out!</a></p>
                  <div className="flex justify-between w-full mt-6 place-items-center">
                    <GatsbyImage
                      image={data.logoGolang.childImageSharp.gatsbyImageData}
                      className="w-24 h-full"
                      alt='Logo of software Golang' />
                    <GatsbyImage
                      image={data.logoMysql.childImageSharp.gatsbyImageData}
                      className="w-20 h-full"
                      alt='Logo of software Mysql' />
                    <GatsbyImage
                      image={data.logoPostgresql.childImageSharp.gatsbyImageData}
                      className="h-full w-14"
                      alt='Logo of software Postgresql' />
                    <GatsbyImage
                      image={data.logoAws.childImageSharp.gatsbyImageData}
                      className="w-16 h-full"
                      alt='Logo of software aws' />
                    {/* <Img fluid={data.logoReact.childImageSharp.fluid} alt='TODO' />
                    <Img fluid={data.logoRedux.childImageSharp.fluid} alt='TODO' />
                    <Img fluid={data.logoStencil.childImageSharp.fluid} alt='TODO' />
                    <Img fluid={data.logoGolang.childImageSharp.fluid} alt='TODO' /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-3 features__item xl:px-0">
            <div className="px-4 py-8 features__text rw-text-block">
              <div className="max-w-screen-sm ">
                <h2 className="h1">B2B interfaces with consumer product usability and quality.</h2>
                <p>Building the best interfaces for the rental industry is our daily business and we love creating them. It’s challenging and rewarding at the same time, owing to the fact that our customer needs are broad and expectations high.</p>
                <a href="mailto:team@rentware.com" className="text-xl no-underline btn btn--primary">Got ideas? E-mail us now.</a>
              </div>
            </div>
            <div className="w-full max-w-lg px-4 mx-auto lg:ml-auto features__image xl:px-0 lg:-mr-8">
              <div className="overflow-hidden shadow-lg rounded-2xl">
                <div className="relative">
                  <GatsbyImage image={data.jobCard2.childImageSharp.gatsbyImageData} alt='TODO' />
                  <h3 className="absolute bottom-0 left-0 px-5 py-2 text-2xl font-bold text-white bg-primary-font/80 w-52">Full-stack</h3>
                </div>
                <div className="relative p-8 pt-20 text-lg">
                  <h4 className="absolute top-0 left-0 px-5 py-2 font-bold text-white bg-primary-font/80 w-52">Software engineer</h4>
                  <p className="mb-2">We are looking for a full-stack engineer, preferable with pre-existing knowledge in Golang and React to build and improve our interfaces.</p>
                  <p>Sounds interesting? <a className="underline" href="mailto:team@rentware.com">Super, just reach out!</a></p>
                  <div className="flex justify-between w-full mt-6 place-items-center">
                    <GatsbyImage
                      image={data.logoGolang.childImageSharp.gatsbyImageData}
                      className="w-24 h-full"
                      alt='Logo of software Golang' />
                    <GatsbyImage
                      image={data.logoReact.childImageSharp.gatsbyImageData}
                      className="w-16 h-full"
                      alt='Logo of software react' />
                    <GatsbyImage
                      image={data.logoRedux.childImageSharp.gatsbyImageData}
                      className="w-16 h-full"
                      alt='Logo of software redux' />
                    <GatsbyImage
                      image={data.logoStencil.childImageSharp.gatsbyImageData}
                      className="w-32 h-full"
                      alt='Logo of software stencil' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-2 max-w-6xl mx-auto my-16 text-4xl text-center lg:my-28">
            <h3 className="p-4 xl:p-0">We unfold the potential of every rental business with the best software solution for all booking channels and back-office applications to make work routines almost effortless and maximize exposure and conversion.</h3>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <GatsbyImage
            image={data.teamMeeting.childImageSharp.gatsbyImageData}
            alt='Göran presents in a team meeting' />
          <GatsbyImage
            image={data.karolAndMilan.childImageSharp.gatsbyImageData}
            alt='Milan and Karol do quite a bit of thinking in front of the computer' />
          <GatsbyImage
            image={data.teamEventTennis.childImageSharp.gatsbyImageData}
            alt='happy and a bit silly posing of the rentware team at an indoor tennis court' />
          <GatsbyImage
            image={data.office.childImageSharp.gatsbyImageData}
            alt='impression of the rentware team in the office at work' />
        </div>
      </main>

    </Layout>
  );
}

export default JobsPage;
